<template>
  <div class="color_white">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="绑定商品" prop="businessNo">
        <a-button type="primary" @click="modalVisible = true"> {{ isAdd ? '添加商品' : '修改商品'}} </a-button>
        <div v-if="tableList.length > 0">
          <ProductCard :table-list="tableList" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="businessType">
        <a-radio-group v-model="form.businessType">
          <a-radio v-for="item in businessTypeList" :key="item.name" :value="item.val">
            {{ item.type }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="三方平台" prop="thirdPlatform">
        <a-select v-model="form.thirdPlatform" style="width: 200px" >
          <a-select-option v-for="item in thirdPlat" :key="item.val" :value="item.val">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="productId" label="三方商品号" prop="productId">
        <a-input
          v-model="form.productId"
          style="width: 200px"
          placeholder="请输入三方商品号"
          @blur="
            () => {
              $refs.productId.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          {{ isAdd ? '添加' : '修改'}}
        </a-button>
        <a-button style="margin-left: 10px;" @click="$router.go(-1)">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>

    <RadioModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :type="0"
      :isShowMember="true"
      :selectedRowKeys="selectedRowKeys"
      @cancel="modalVisible = false"
      @ok="handleOk"
    />
  </div>
</template>
<script>
import { thirdPlat, businessTypeList, businessTypeMap } from './constants'
import { relationDetailAPI, editRelationAPI, addRelationAPI } from '@/request/api/orderformManage'
import ProductCard from '@/components/product/ProductCard.vue'
import RadioModal from "@/components/courseMember/newRadioModal";
export default {
  components: { RadioModal, ProductCard },
  data() {
    return {
      businessTypeList,
      thirdPlat,
      businessTypeMap,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {
        businessNo: '',
        thirdPlatform: 1,
        businessType: 1,
        productId: ''
      },
      rules: {
        businessNo: [
          { required: true, message: '请选择商品', trigger: 'change'},
        ],
        productId: [
          { required: true, message: '请输入三方商品号', trigger: 'blur' }
        ],
        thirdPlatform: [{ required: true, message: '请选择三方平台', trigger: 'change' }],
        businessType: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
      },
      modalVisible: false,
      isAdd: true,
      id: '',
      tableList: [],
      selectedRowKeys: []
    };
  },
  created() {
    const id = this.$route.params.id
    this.isAdd = !+id
    if (!this.isAdd) {
      this.id = id
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id.toString()
      }
      const { code, data } = await relationDetailAPI(params)
      if (code !== 200) return
      this.form = data.relationdata
      this.tableList.push(data.businessData)

    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isAdd ? this.addRelation() : this.editRelation()
        }
      });
    },
    async addRelation() {
      const params = {
        thirdPlatForm: this.form.thirdPlatform,
        ...this.form
      }
      const { code } = await addRelationAPI(params) 
      if (code !== 200) return
      this.$message.success('添加成功')
      this.$router.push('/orderformManage/productBinding')
    },
    async editRelation() {
      const params = {
        thirdPlatForm: this.form.thirdPlatform,
        ...this.form
      }
      const { code } = await editRelationAPI(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.$router.push('/orderformManage/productBinding')
    },
    // 弹窗中确认按钮事件
    handleOk (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
			this.tableList = [selectedRows]
			if (this.tableList[0].memberNo) {
				this.$set(this.form, 'businessNo', this.tableList[0].memberNo)
			} else {
				this.$set(this.form, 'businessNo', selectedRowKeys[0])
			}
      this.modalVisible = false;
    },
  },
};
</script>
